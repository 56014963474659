<template>
  <v-skeleton-loader :loading="status.reading" type="article">
    <v-sheet v-bind="propsCompSheet" data-cy="organisations-edit-appearance">
      <div class="mb-4">
        <v-form :disabled="formDisabled" @submit.prevent>
          <!-- 1. Logo -->
          <div class="mb-2">{{ $t("common.logo") }}</div>
          <v-alert type="info" outlined class="my-4">{{
            $t("appearance.logoInfo")
          }}</v-alert>
          <div class="formField d-flex align-center mb-4">
            <div class="mr-2">
              <v-avatar color="transparent" size="3em" rounded="0">
                <v-img
                  :src="logoUrl(organisation.id)"
                  contain
                  :key="forceLogoReload"
                ></v-img>
              </v-avatar>
            </div>
            <v-file-input
              v-bind="propsFormFields"
              :label="$t('common.logo')"
              show-size
              v-model="editUploads"
            >
            </v-file-input>
            <div class="ml-2">
              <v-btn
                v-bind="propsButtonIconAction"
                small
                @click="clickDelete"
                data-cy="button-delete"
              >
                <v-icon>$trash</v-icon>
              </v-btn>
            </div>
          </div>

          <v-divider class="my-8"></v-divider>

          <!-- 2. Primary colour -->
          <div class="mb-2">
            {{ $t("organisations.appearance.colourPrimary") }}
          </div>
          <v-alert type="info" outlined class="my-4">{{
            $t("appearance.colourPrimaryInfo")
          }}</v-alert>
          <div class="formField">
            <v-color-picker
              v-bind="propsFormFields"
              v-model="editColourPrimary"
            ></v-color-picker>
          </div>

          <v-divider class="my-8"></v-divider>

          <!-- 3. Tree view options -->
          <div class="mb-2">{{ $t("appearance.treeviewViewTitle") }}</div>
          <v-alert type="info" outlined class="my-4">{{
            $t("appearance.treeviewViewInfo")
          }}</v-alert>
          <div>
            <!-- Header -->
            <v-row>
              <v-col cols="6"></v-col>
              <v-col cols="3" class="d-flex justify-center text-center">{{
                $t("users.roles.ROLE_USER_R")
              }}</v-col>
              <v-col cols="3" class="d-flex justify-center text-center">{{
                $t("users.roles.ROLE_USER_RW")
              }}</v-col>
            </v-row>

            <!-- Owner -->
            <v-row class="grey lighten-3 pt-2">
              <v-col cols="3">{{ $t("users.owner") }}</v-col>
              <v-col cols="3">{{ $t("appearance.treeviewView") }}</v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.viewOwnersUserR"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.viewOwnersUserRW"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
            </v-row>
            <v-row class="grey lighten-3 pb-2">
              <v-col cols="3"></v-col>
              <v-col cols="3">{{ $t("appearance.treeviewModify") }}</v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.modifyOwnersUserR"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.modifyOwnersUserRW"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
            </v-row>

            <!-- File name -->
            <v-row class="pt-2">
              <v-col cols="3">{{ $t("files.fileName") }}</v-col>
              <v-col cols="3">{{ $t("appearance.treeviewView") }}</v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.viewFileNameUserR"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.viewFileNameUserRW"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col cols="3"></v-col>
              <v-col cols="3">{{ $t("appearance.treeviewModify") }}</v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.modifyFileNameUserR"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.modifyFileNameUserRW"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
            </v-row>

            <!-- File size -->
            <v-row class="grey lighten-3 pt-2">
              <v-col cols="3">{{ $t("files.fileSize") }}</v-col>
              <v-col cols="3">{{ $t("appearance.treeviewView") }}</v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.viewFileSizeUserR"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.viewFileSizeUserRW"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
            </v-row>
            <v-row class="grey lighten-3 pb-2">
              <v-col cols="3"></v-col>
              <v-col cols="3">{{ $t("appearance.treeviewModify") }}</v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.modifyFileSizeUserR"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.modifyFileSizeUserRW"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
            </v-row>

            <!-- Public / private -->
            <v-row class="pt-2">
              <v-col cols="3">{{ $t("files.publicPrivate") }}</v-col>
              <v-col cols="3">{{ $t("appearance.treeviewView") }}</v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.viewPublicPrivateUserR"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.viewPublicPrivateUserRW"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
            </v-row>
            <v-row class="pb-2">
              <v-col cols="3"></v-col>
              <v-col cols="3">{{ $t("appearance.treeviewModify") }}</v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.modifyPublicPrivateUserR"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.modifyPublicPrivateUserRW"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
            </v-row>

            <!-- File access -->
            <v-row class="py-2">
              <v-col cols="3">{{ $t("documentsAccess.title") }}</v-col>
              <v-col cols="3">{{ $t("appearance.treeviewView") }}</v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.viewAccessLogUserR"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
              <v-col cols="3" class="d-flex justify-center">
                <v-simple-checkbox
                  v-bind="propsFormFields"
                  v-model="editTreeViewOptions.viewAccessLogUserRW"
                  class="align-self-start"
                ></v-simple-checkbox>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>

      <v-divider class="my-8"></v-divider>

      <div class="d-flex">
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="authCanByOrganisationId('editOwn.organisations', objectId)"
          v-bind="propsButtonAction"
          :loading="status.loading"
          :disabled="!formReadyForSubmit"
          @click="clickUpdate"
          data-cy="button-update"
          >{{ $t("common.update") }}</v-btn
        >
      </div>
    </v-sheet>
  </v-skeleton-loader>
</template>

<script>
import Permissions from "@/modules/auth/mixins/Permissions.mixin.js";
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import FormCommonErrors from "@/modules/base/formCommonErrors.mixin";

export default {
  name: "OrganisationsEdit",
  mixins: [Permissions, ComponentStatus, FormCommonErrors],
  components: {},
  props: {
    objectId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    firstFormClick: true,
    changed: false,
    forceLogoReload: 0,
    // Edit proxy form data
    editColourPrimary: "#242424",
    editUploads: undefined,

    // This has to match the FoldersTree.vue
    editTreeViewOptions: {
      viewOwnersUserR: false,
      viewFileNameUserR: true,
      viewFileSizeUserR: false,
      viewPublicPrivateUserR: true,

      modifyOwnersUserR: true,
      modifyFileNameUserR: true,
      modifyFileSizeUserR: true,
      modifyPublicPrivateUserR: true,

      viewOwnersUserRW: false,
      viewFileNameUserRW: true,
      viewFileSizeUserRW: false,
      viewPublicPrivateUserRW: true,

      modifyOwnersUserRW: true,
      modifyFileNameUserRW: true,
      modifyFileSizeUserRW: true,
      modifyPublicPrivateUserRW: true,

      viewAccessLogUserR: true,
      viewAccessLogUserRW: true,
    },
  }),
  validations: {
    editColourPrimary: {},
    editUploads: {},
  },
  computed: {
    logoUrl() {
      return (id) => {
        return (
          this.$store.getters["organisations/getLogoUrl"](id) +
          `?cache=${this.forceLogoReload}`
        );
      };
    },
    formDisabled() {
      // if (this.isAdmin) {
      //   return false;
      // }
      return false;
    },
    formReadyForSubmit() {
      if (this.status.loading) {
        return false;
      }
      if (this.status.readError) {
        return false;
      }
      // if (this.isAdmin) {
      //   return true;
      // }
      if (this.firstFormClick) {
        return true;
      }
      if (this.$v.$invalid) {
        return false;
      }
      if (!this.$v.$anyDirty) {
        return false;
      }
      return true;
    },
    // 2) When the organisation id changes we use the store getter to "get" the
    // object that has (or the temporary one that will have) the organisation data.
    // Note that this is different than the store action used to read the
    // actual data from the backend, they both work "in parallel".
    organisation() {
      return this.$store.getters["organisations/readById"](this.objectId);
    },
    newAppearance() {
      let newAppearance = {};

      if (this.editColourPrimary && this.editColourPrimary !== "#242424") {
        let colour = /#.{6}/.exec(this.editColourPrimary);
        newAppearance.colour = {
          primary: colour[0],
        };
      }

      newAppearance.treeViewOptions = this.editTreeViewOptions;

      return newAppearance;
    },
  },
  watch: {
    // 3) If the organisation id changes and we have not edit any field we trigger
    // the store action to load the data. Note that this is different than
    // the getter used to display the data, they both work "in parallel".
    objectId: {
      immediate: true,
      handler: function () {
        if (!this.changed) {
          this.setStatusReading();
          this.editOrganisationId = this.objectId;
          this.$store
            .dispatch("organisations/readById", this.objectId)
            .then((/* result */) => {
              this.setStatusReadSuccess();
            })
            .catch((/* error */) => {
              this.setStatusReadError();
            });
        }
      },
    },
    // 4) In case we load new organisation data (and we are not in the middle of
    // editing) we update our "proxy" form editable variables to reflect
    // what we have just loaded.
    organisation: {
      immediate: true,
      handler: function (newValue /* , oldValue */) {
        this.refreshFormData(newValue);
      },
    },
  },
  created() {},
  methods: {
    refreshFormData(value) {
      let newValue = this.organisation;
      if (value) {
        newValue = value;
      }
      if (!this.changed) {
        if (newValue.appearance) {
          let appearance = JSON.parse(newValue.appearance);
          if (appearance.colour) {
            if (appearance.colour.primary) {
              this.editColourPrimary = appearance.colour.primary;
            }
          }

          if ("treeViewOptions" in appearance) {
            // USER_RW

            // Modify options
            if ("modifyOwnersUserRW" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.modifyOwnersUserRW === true) {
                this.editTreeViewOptions.modifyOwnersUserRW = true;
              } else {
                this.editTreeViewOptions.modifyOwnersUserRW = false;
              }
            }
            if ("modifyFileNameUserRW" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.modifyFileNameUserRW === true) {
                this.editTreeViewOptions.modifyFileNameUserRW = true;
              } else {
                this.editTreeViewOptions.modifyFileNameUserRW = false;
              }
            }
            if ("modifyFileSizeUserRW" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.modifyFileSizeUserRW === true) {
                this.editTreeViewOptions.modifyFileSizeUserRW = true;
              } else {
                this.editTreeViewOptions.modifyFileSizeUserRW = false;
              }
            }
            if ("modifyPublicPrivateUserRW" in appearance.treeViewOptions) {
              if (
                appearance.treeViewOptions.modifyPublicPrivateUserRW === true
              ) {
                this.editTreeViewOptions.modifyPublicPrivateUserRW = true;
              } else {
                this.editTreeViewOptions.modifyPublicPrivateUserRW = false;
              }
            }

            // View options
            if ("viewOwnersUserRW" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.viewOwnersUserRW === true) {
                this.editTreeViewOptions.viewOwnersUserRW = true;
              } else {
                this.editTreeViewOptions.viewOwnersUserRW = false;
              }
            }
            if ("viewFileNameUserRW" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.viewFileNameUserRW === true) {
                this.editTreeViewOptions.viewFileNameUserRW = true;
              } else {
                this.editTreeViewOptions.viewFileNameUserRW = false;
              }
            }
            if ("viewFileSizeUserRW" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.viewFileSizeUserRW === true) {
                this.editTreeViewOptions.viewFileSizeUserRW = true;
              } else {
                this.editTreeViewOptions.viewFileSizeUserRW = false;
              }
            }
            if ("viewPublicPrivateUserRW" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.viewPublicPrivateUserRW === true) {
                this.editTreeViewOptions.viewPublicPrivateUserRW = true;
              } else {
                this.editTreeViewOptions.viewPublicPrivateUserRW = false;
              }
            }
            if ("viewAccessLogUserRW" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.viewAccessLogUserRW === true) {
                this.editTreeViewOptions.viewAccessLogUserRW = true;
              } else {
                this.editTreeViewOptions.viewAccessLogUserRW = false;
              }
            }

            // USER_R

            // Modify options
            if ("modifyOwnersUserR" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.modifyOwnersUserR === true) {
                this.editTreeViewOptions.modifyOwnersUserR = true;
              } else {
                this.editTreeViewOptions.modifyOwnersUserR = false;
              }
            }
            if ("modifyFileNameUserR" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.modifyFileNameUserR === true) {
                this.editTreeViewOptions.modifyFileNameUserR = true;
              } else {
                this.editTreeViewOptions.modifyFileNameUserR = false;
              }
            }
            if ("modifyFileSizeUserR" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.modifyFileSizeUserR === true) {
                this.editTreeViewOptions.modifyFileSizeUserR = true;
              } else {
                this.editTreeViewOptions.modifyFileSizeUserR = false;
              }
            }
            if ("modifyPublicPrivateUserR" in appearance.treeViewOptions) {
              if (
                appearance.treeViewOptions.modifyPublicPrivateUserR === true
              ) {
                this.editTreeViewOptions.modifyPublicPrivateUserR = true;
              } else {
                this.editTreeViewOptions.modifyPublicPrivateUserR = false;
              }
            }

            // View options
            if ("viewOwnersUserR" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.viewOwnersUserR === true) {
                this.editTreeViewOptions.viewOwnersUserR = true;
              } else {
                this.editTreeViewOptions.viewOwnersUserR = false;
              }
            }
            if ("viewFileNameUserR" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.viewFileNameUserR === true) {
                this.editTreeViewOptions.viewFileNameUserR = true;
              } else {
                this.editTreeViewOptions.viewFileNameUserR = false;
              }
            }
            if ("viewFileSizeUserR" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.viewFileSizeUserR === true) {
                this.editTreeViewOptions.viewFileSizeUserR = true;
              } else {
                this.editTreeViewOptions.viewFileSizeUserR = false;
              }
            }
            if ("viewPublicPrivateUserR" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.viewPublicPrivateUserR === true) {
                this.editTreeViewOptions.viewPublicPrivateUserR = true;
              } else {
                this.editTreeViewOptions.viewPublicPrivateUserR = false;
              }
            }
            if ("viewAccessLogUserR" in appearance.treeViewOptions) {
              if (appearance.treeViewOptions.viewAccessLogUserR === true) {
                this.editTreeViewOptions.viewAccessLogUserR = true;
              } else {
                this.editTreeViewOptions.viewAccessLogUserR = false;
              }
            }
          }
        }
      }
    },
    clickUpdate() {
      if (this.firstFormClick) {
        this.firstFormClick = false;
        this.$v.$touch();
      }
      if (!this.$v.$invalid) {
        if (this.editUploads) {
          this.sendUpdateWithFile();
        } else {
          this.sendUpdate();
        }
      }
    },
    sendUpdate(fileEvent = null) {
      this.setStatusUpdating();
      let payload = {
        id: this.editOrganisationId,
        appearance: JSON.stringify(this.newAppearance),
      };
      if (fileEvent) {
        let file = fileEvent.target.result;

        payload.file = file;
        payload.fileName = this.editUploads.name;
        payload.fileType = this.editUploads.type;
      }
      this.$store
        .dispatch("organisations/update", payload)
        .then((/* result */) => {
          this.setStatusUpdateSuccess();
          this.$store.commit("status/showSuccess");
          this.$store.commit(
            "auth/setNewOrganisationAppearance",
            this.newAppearance
          );
          this.changed = false;
          if (fileEvent) {
            this.forceLogoReload++;
          }
          // this.refreshFormData();
        })
        .catch((/* error */) => {
          this.setStatusUpdateError();
          this.$store.commit("status/showError");
        });
    },
    sendUpdateWithFile() {
      this.setStatusUpdating();
      const reader = new FileReader();
      reader.onload = (event) => {
        this.sendUpdate(event);
      };
      reader.readAsDataURL(this.editUploads);
    },
    clickDelete() {
      this.setStatusUpdating();
      let payload = {
        id: this.editOrganisationId,
        fileName: "REMOVE",
      };
      this.$store
        .dispatch("organisations/update", payload)
        .then((/* result */) => {
          this.setStatusUpdateSuccess();
          this.$store.commit("status/showSuccess");
          this.changed = false;
          this.forceLogoReload++;
          // this.refreshFormData();
        })
        .catch((/* error */) => {
          this.setStatusUpdateError();
          this.$store.commit("status/showError");
        });
    },
  },
};
</script>
